<template>
  <div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="점검자 목록"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :data="patrol.patrolPersonModelList"
        :isExcelDown="false"
        :filtering="false"
        :columnSetting="false"
        :usePaging="false"
        noDataLabel="항목별 결과 추가하세요."
        :hideBottom="true"
        :editable="editable && !disabled"
        rowKey="saiPatrolPersonId"
        selection="multiple"
      >
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name ==='patrolPersonName'">
            <c-text-column
              v-if="props.row['patrolTypeCd'] === 'N'"
              :editable="editable"
              :props="props"
              :col="col"
              :disabled="disabled"
              v-model="props.row['patrolPersonName']"
            />
            <span v-else>
              {{props.row['patrolPersonName']}}
            </span>
          </template>
          <template v-if="col.name ==='patrolJobName'">
            <c-text-column
              v-if="props.row['patrolTypeCd'] === 'N'"
              :editable="editable"
              :props="props"
              :col="col"
              :disabled="disabled"
              v-model="props.row['patrolJobName']"
            />
            <span v-else>
              {{props.row['patrolJobName']}}
            </span>
          </template>
        </template>
        <template slot="table-button">
          <q-btn-group outline>
            <c-btn
              v-if="editable && !disabled"
              :showLoading="false"
              label="점검자 선택"
              icon="add"
              @btnClicked="selectUser"
            />
            <c-btn
              v-if="editable && !disabled"
              :showLoading="false"
              label="직접추가"
              icon="add"
              @btnClicked="add"
            />
            <c-btn
              v-if="
                editable &&
                !disabled &&
                patrol.patrolPersonModelList.length > 0
              "
              :showLoading="false"
              label="제외"
              icon="remove"
              @btnClicked="remove"
            />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { uid } from "quasar";
export default {
  name: 'patrol-result',
  props: {
    patrol: {
      type: Object,
      default: () => ({
        saiPatrolId: '',
        vendorCd: '',
        patrolPersonModelList: [],
        deletePatrolPersonModelList: [],
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      editable: true,
      detailUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          
        ],
        height: "420px",
        data: [],
      },
      saveCall: {
        saveCallData: '',
      },
      getUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // list setting
      this.setHeader();
    },
    setHeader() {
      this.grid.columns = [
        {
          name: "patrolPersonName",
          field: "patrolPersonName",
          label: "성명",
          style: 'width:25%',
          type: 'custom',
          align: "center",
          sortable: false,
        },
        {
          name: "patrolJobName",
          field: "patrolJobName",
          label: "역할/직책",
          style: 'width:25%',
          type: 'custom',
          align: "center",
          sortable: false,
        },
        {
          name: 'remark',
          field: 'remark',
          label: '비고',
          style: 'width:50%',
          align: 'left',
          type: 'textarea',
          sortable: false,
        },
      ]
    },
    closeUserPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.patrol.patrolPersonModelList, {
            patrolPersonId: _item.userId,
          });
          if (index === -1) {
            this.patrol.patrolPersonModelList.push({
              saiPatrolId: this.patrol.saiPatrolId,
              saiPatrolPersonId: uid(),
              patrolTypeCd: 'Y',
              patrolPersonId: _item.userId,
              patrolPersonName: _item.userName,
              patrolJobName: _item.projectRoleName ? _item.projectRoleName : _item.vendorUserRoleName,
              remark: '',
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    selectUser() {
      if (!this.patrol.vendorCd) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "업체를 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
        return;
      } 
      // 직영일때
      this.popupOptions.title = "점검자 검색"; // TBM 참석자 검색
      this.popupOptions.param = {
        type: "multiple",
        isUserAll: this.patrol.vendorCd==='VEN0000000' ? true : false,
        plantCd: this.patrol.plantCd,
        vendorCd: this.patrol.vendorCd
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "100%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeUserPopup;
    },
    add() {
      this.patrol.patrolPersonModelList.splice(0, 0, {
        saiPatrolId: this.patrol.saiPatrolId,
        saiPatrolPersonId: uid(),
        patrolTypeCd: 'N',
        patrolPersonId: '',
        patrolPersonName: '',
        patrolJobName: '',
        remark: '',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    remove() {
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", // 안내
          message: "하나 이상 선택하세요.", // 하나 이상 선택하세요.
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.patrol.deletePatrolPersonModelList) {
            this.patrol.deletePatrolPersonModelList = [];
          }
          if (
            this.$_.findIndex(this.patrol.deletePatrolPersonModelList, {
              saiPatrolPersonId: item.saiPatrolPersonId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.patrol.deletePatrolPersonModelList.push(item);
          }
          this.patrol.patrolPersonModelList = this.$_.reject(
            this.patrol.patrolPersonModelList,
            item
          );
        });
      }
    },
  }
};
</script>
