var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "점검자 목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.patrol.patrolPersonModelList,
                isExcelDown: false,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                noDataLabel: "항목별 결과 추가하세요.",
                hideBottom: true,
                editable: _vm.editable && !_vm.disabled,
                rowKey: "saiPatrolPersonId",
                selection: "multiple",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "patrolPersonName"
                        ? [
                            props.row["patrolTypeCd"] === "N"
                              ? _c("c-text-column", {
                                  attrs: {
                                    editable: _vm.editable,
                                    props: props,
                                    col: col,
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: props.row["patrolPersonName"],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        props.row,
                                        "patrolPersonName",
                                        $$v
                                      )
                                    },
                                    expression: "props.row['patrolPersonName']",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row["patrolPersonName"]) +
                                      " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                      col.name === "patrolJobName"
                        ? [
                            props.row["patrolTypeCd"] === "N"
                              ? _c("c-text-column", {
                                  attrs: {
                                    editable: _vm.editable,
                                    props: props,
                                    col: col,
                                    disabled: _vm.disabled,
                                  },
                                  model: {
                                    value: props.row["patrolJobName"],
                                    callback: function ($$v) {
                                      _vm.$set(props.row, "patrolJobName", $$v)
                                    },
                                    expression: "props.row['patrolJobName']",
                                  },
                                })
                              : _c("span", [
                                  _vm._v(
                                    " " +
                                      _vm._s(props.row["patrolJobName"]) +
                                      " "
                                  ),
                                ]),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "점검자 선택",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.selectUser },
                          })
                        : _vm._e(),
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "직접추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.add },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.patrol.patrolPersonModelList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "제외",
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.remove },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }